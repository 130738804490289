// APP STYLES

body {
  overflow: auto;

  &.main-nav-visible {
    padding-top: $topbar-height;
  }

  &.mobile-nav-visible {
    padding-top: $tabbar-height;
  }
}

h1, h2, h3 {
  font-weight: 300;
}

.radius {
  @include radius($global-radius);
}

// Typography

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.small-text {
  font-size: $small-text;
}

.underline {
  text-decoration: underline;
}

.big-text {
  font-size: $big-text;
}

.light-color-text {
  color: $light-font-color;
}

.lighter-color-text {
  color: $lighter-font-color;
}

// Buttons

button, .button {
  .separator {
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    margin: 0 0.9rem;
  }
}

.button-li {
  @include button($button-med, $linkedinColor, false, false, false, false);
}

.button-scribd {
  @include button($button-med, $scribdColor, false, false, false, false);
}

.button-email {
  @include button($button-med, $linkedinColor, false, false, false, false);
  border: 1px solid #027eb0;
  background-color: transparent;
  color: #027eb0;

  &:hover {
    background-color: transparent;
    opacity: .75;
    color: #027eb0;
  }
}

.button-fb, .button-fb.disabled {
  @include button($button-med, $facebookColor, false, false, false, false);
}

.button-tw {
  @include button($button-med, $twitterColor, false, false, false, false);
}

.button-go {
  @include button($button-med, $googleColor, false, false, false, false);
}

.button-transparent {
  $bg: rgba(0, 0, 0, 0.1);
  @include button($button-med, $bg, false, false, false, false);
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.button-grey {
  @include button($button-med, $body-bg, false, false, false, false);
  border: 1px solid $lightest-font-color;
  color: $body-font-color;
}

.button-dark-grey {
  @include button($button-med, $monsoon, false, false, false, false);
  border: 1px solid $steel;
  color: $white;
}

.button-ghost {
  @include button($button-med, $ghost, false, false, false, false);
  color: $body-font-color;
  border: 1px solid scale-color($ghost, $lightness: -25%);

  &:hover {
    color: $body-font-color;
  }
}

.button-turquoise {
  $bg: #21aaac;
  @include button($button-med, $bg, 0, false, false, false);
}

// Trick to get the disabled style working because nesting in the
// .button-yellow below is not working after the button mixin is called
.button-yellow[disabled], .button-yellow[disabled]:hover {
  background-color: #ffce2f;
  color: $body-font-color;
}

.button-yellow {
  $bg: #ffce2f;
  @include button($button-med, $bg, 0, false, false, false);
  color: $body-font-color;
}

.transparent {
  background-color: transparent !important;
  @include single-transition();
  @include radius($global-radius);

  &.button {
    border-color: $primary-color;
    color: $primary-color;

    &.secondary {
      border-color: $secondary-color;
      color: $secondary-color;
    }

    &.button-grey {
      border-color: $light-font-color;
      color: $light-font-color;
    }

    &.success {
      border-color: $success-color;
      color: $success-color;
    }

    &.alert {
      border-color: $alert-color;
      color: $alert-color;
    }
  }

  &.has-border {
    border-width: 1px;
  }
}

.button-social, .button-social.disabled {
  font-size: 1.1rem;

  .fa {
    font-size: 1.4rem;
  }

  &.small .fa {
    font-size: 1.1rem;
  }
}

.button-outline-primary {
  border-color: $primary-color;
  color: $primary-color;
}

.button-follow {
  background-color: $primary-color;
  color: #FFFFFF;
  background-image: none;
  text-shadow: none;
}

.button-unfollow {
  background-color: #636C8B;
  color: #FFFFFF;
  background-image: none;
  text-shadow: none;
}


a.button-social, a.button-social:hover {
  text-decoration: none;
}

// SS Tabs
// TODO (amercier): consolidate all foundation customizations

.tabs {
  border-bottom: 1px solid $lightest-font-color;

  dd,
  li,
  .tab-title {
    a {
      padding-bottom: rem-calc(6);
    }

    &.active a {
      border-bottom: 3px solid $secondary-color;
    }
  }

  &.primary {
    dd,
    li,
    .tab-title {
      &.active a {
        border-bottom-color: $primary-color;
      }
    }
  }
}

.button-link {
  background: none !important;
  border: 0;
  color: $primary-color !important;
  margin: 0;

  &:focus {
    outline: none;
  }
}

// Makes element visually hidden, but exposed to assistive tech for accessibility.
// Foundations utility doesn't address bug with concatenated screen reader output.
// https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
.visually-hidden {
  @include element-invisible;
  white-space: nowrap;
}