@import "base/modernize-vars";
@import "/frontend/styles/osano-dialog.css";

.osano-cm-message {
  font-size: 12px;
  margin-bottom: 1em;
}

.osano-cm-list {
  margin-bottom: 10px;
  margin-top: 5px;
}

.osano-cm-list__list-item {
  font-size: 12px;
}

.osano-cm-drawer-links {
  margin: 0;
}

.osano-cm-dialog__list {
  margin: 0;
}

.osano-cm-info,
.osano-cm-dialog:not(.osano-cm-dialog--eu, .osano-cm-dialog--br) {
  font-family: "Source Sans Pro", sans-serif;
  padding: 16px;
}

.osano-cm-label {
  color: #fff;
  font-size: 12px;
}

.osano-cm-link {
  color: $blue-dark;
  font-size: 12px;
}

.osano-cm-drawer-toggle {
  .osano-cm-label {
    color: #4d4d4d;
  }
}

.osano-cm-button {
  padding: 10px;
  margin: 3px 0;
  font-size: 14px;
  height: 44px;
}

.osano-cm-accept,
.osano-cm-accept-all,
.osano-cm-save,
.osano-cm-manage {
  border-color: $blue-dark;
}

.osano-cm-denyAll {
  background-color: $blue-gray-800;
  border-color: #ffffff;
  color: #ffffff;
}

.osano-cm-close {
  padding: 12px;
  margin: 0;
  margin-left: auto;
  display: block;
  position: relative;
  stroke: $blue-gray-400;

  position: absolute;
  top: 8px;
  right: 8px;

  &:hover,
  &:focus {
    stroke-width: 1px;
    background-color: transparent;
    border-color: transparent;
    stroke: #fff;
  }
}

.osano-cm-toggle {
  margin: 0.6em 0;
}

.osano-cm-content__message {
  margin-bottom: 8px;
  padding-bottom: 0;
  width: 90%;
}

.osano-cm-buttons {
  column-gap: 6px;
}

.osano-cm-usage-list {
  font-size: 12px;
  line-height: normal;
  margin-bottom: 8px;
  width: 90%;
}

.osano-cm-usage-list__list {
  list-style-position: outside;
  padding-left: 13px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: normal;
}

.osano-cm-widget {
  display: none;
}

@media only screen and (max-width: 480px) {
  .osano-cm-info,
  .osano-cm-dialog {
    padding: 16px;
  }

  .osano-cm-dialog--type_box.osano-cm-dialog--position_bottom-right {
    left: 0;
    right: 0;
    max-width: unset;
    width: 100%;
    bottom: 0;
  }
}
