:root {
  --shadow-color: rgba(122 61 143 / 0.15);
  --osano-dialog-shadow: drop-shadow(var(--shadow-color) 0.5px 1px 1px) drop-shadow(var(--shadow-color) 1px 2px 2px)
    drop-shadow(var(--shadow-color) 2px 4px 4px) drop-shadow(var(--shadow-color) 4px 8px 8px)
    drop-shadow(var(--shadow-color) 8px 16px 16px);
}

.osano-cm-dialog {
  background-color: #212240;
  color: var(--white);
}
.osano-cm-dialog__close {
  color: inherit;
  stroke: var(--white)
}
.osano-cm-dialog--eu {
  display: grid;
  grid-template-columns: 1fr 200px;
  column-gap: 48px;
  max-height: 95dvh;

  font-family: "Source Sans Pro", sans-serif;
  color: var(--blue-gray-600, #636c8b);
  background-color: var(--white, #fff);

  border-top-left-radius: var(--border-radius, 8px);
  border-top-right-radius: var(--border-radius, 8px);
  filter: var(--osano-dialog-shadow);

  padding-block-start: 32px;
  padding-block-end: 20px;
  padding-inline: 5%;

  .osano-cm-dialog__content {
    max-height: calc(95dvh - 52px);
  }

  .osano-cm-dialog__close {
    display: none;
  }

  .osano-cm-dialog__title {
    font-size: 24px;
    font-weight: 700;
    color: var(--blue-gray-900, #0e0f25);
    margin-block-end: 16px;
  }

  /* usage list */
  .osano-cm-content__usage-list {
    font-size: 16px;
  }
  .osano-cm-usage-list__list {
    margin-block-start: 8px;
    margin-block-end: 16px;
    margin-inline-start: 8px;

    .osano-cm-list__list-item {
      font-size: 16px;
    }
  }

  .osano-cm-message,
  .osano-cm-link {
    font-size: 16px;
  }

  .osano-cm-message {
    display: flex;
  }

  /* links */
  .osano-cm-link,
  .osano-cm-drawer-links {
    display: inline;
  }

  .osano-cm-link {
    color: var(--celadon-blue, #027eb0);
    font-weight: 600;
    text-decoration: none;

    &:hover {
      color: var(--celadon-blue-dark, #026c97);
    }
    &::after {
      content: "|";
      padding-inline: 12px;
    }
    &:last-child::after {
      content: "";
    }
  }

  /* toggles */
  .osano-cm-dialog__list {
    line-height: normal;
    margin-block-start: 24px;

    .osano-cm-list__list-item {
      display: inline-flex;
      align-items: center;

      &::after {
        content: "|";
        padding-inline: 16px;
        color: var(--blue-gray-200, #e3e6f0);
        font-size: 28px;
      }
      &:last-child::after {
        content: "";
      }
    }
  }
  .osano-cm-toggle__switch {
    display: none;
  }
  .osano-cm-toggle__input {
    width: 16px;
    height: 16px;
    margin: unset;
    overflow: unset;

    accent-color: var(--blue-gray-900, #0e0f25);
    position: static;
    opacity: 1;
  }
  .osano-cm-toggle__label.osano-cm-label {
    color: var(--blue-gray-600, #636c8b);
    font-size: 18px;
    font-weight: 600;
    margin-left: 8px;
    margin-inline-end: 0;
  }

  /* actions buttons */
  .osano-cm-dialog__buttons {
    min-width: unset;
    align-self: flex-end;
    display: grid;
    row-gap: 8px;
    justify-content: stretch;
    align-items: center;
    margin: unset;

    .osano-cm-buttons__button {
      height: max-content;
      min-height: 40px;
      font-size: 16px;
      font-weight: 600;
      margin: unset;
    }
    .osano-cm-accept-all {
      order: -1;
    }
    .osano-cm-manage,
    .osano-cm-accept-all {
      background-color: var(--celadon-blue, #027eb0);
    }
    .osano-cm-save,
    .osano-cm-deny,
    .osano-cm-denyAll {
      background-color: transparent;
      color: var(--celadon-blue, #027eb0);
      border: 1px solid var(--celadon-blue, #027eb0);
    }
  }
}

.osano-cm-dialog--eu.osano-cm-dialog--br {
  .osano-cm-dialog__title {
    font-size: 26px;
    font-weight: 600;
  }

  .osano-cm-dialog__buttons {
    row-gap: 12px;
  }
}

.osano-cm-window:has(> .osano-cm-dialog--br-overlay)::before {
  content: '';
  position: fixed;
  inset: 0;
  background-color: rgba(0 0 0 / 0.6);
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .osano-cm-dialog--eu {
    column-gap: 24px;
    grid-template-columns: 1fr;

    padding-block-end: 32px;
    padding-inline: 16px;

    .osano-cm-dialog__buttons {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;

      .osano-cm-buttons__button {
        width: max-content;
      }
    }
  }

  .osano-cm-dialog--eu.osano-cm-dialog--br {
    .osano-cm-message,
    .osano-cm-link {
      font-size: 16px;
      width: auto;
    }

    .osano-cm-dialog__buttons {
      margin-block-start: 24px;
      row-gap: 16px;
    }
  }
}

@media screen and (max-width: 520px) {
  .osano-cm-dialog--eu {
    font-size: 14px;
    padding-block-start: 16px;
    padding-block-end: 16px;

    .osano-cm-dialog__title {
      font-size: 20px;
      margin-block-end: 8px;
    }

    .osano-cm-message,
    .osano-cm-link {
      font-size: inherit;
    }

    .osano-cm-usage-list__list {
      font-size: 12px;

      .osano-cm-list__list-item {
        font-size: inherit;
      }
    }

    .osano-cm-toggle__label.osano-cm-label {
      font-size: 15px;
    }

    .osano-cm-dialog__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-block-start: 8px;

      .osano-cm-list__list-item {
        display: grid;
        border-bottom: 1px solid var(--blue-gray-200, #e3e6f0);
        padding-block: 4px;

        &:last-child {
          border-bottom: unset;
        }
      }
      .osano-cm-list__list-item::after {
        content: unset;
      }
      .osano-cm-list-item__toggle {
        align-self: baseline;
        justify-content: space-between;

        .osano-cm-toggle__label {
          order: -1;
          margin: unset;
        }
      }
    }

    .osano-cm-dialog__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
      row-gap: 12px;
      margin-block-start: 0;

      .osano-cm-buttons__button {
        min-width: max-content;
        width: unset;
        justify-self: stretch;
      }

      .osano-cm-accept-all {
        grid-column: 1 / span 2;
      }
    }
  }

  .osano-cm-dialog--eu.osano-cm-dialog--br {
    padding-block-start: 32px;
    padding-block-end: 32px;

    .osano-cm-dialog__title {
      font-size: 26px;
      margin-block-end: 16px;
    }

    .osano-cm-message,
    .osano-cm-link {
      font-size: 16px;
      width: auto;
    }

    .osano-cm-dialog__buttons {
      margin-block-start: 24px;
      row-gap: 16px;
    }
  }
}
