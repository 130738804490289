@import "../base/modernize";
@import "base/responsive";
@import "bigboot/modals";

$main-nav-height: 75px;
$logo-image-width: 120px;
$logo-image-height: 30px;
$avatar-size: 40px;

header#main-nav {
  background-color: #fff;
  font-family: $font-family;
  position: relative;
  z-index: 100;
  padding: 0 24px;

  #user-dropdown-trigger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 100vmax;
    padding: 0;
    margin: 0;
  }

  #user-dropdown {
    div {
      @include responsive-flex;
      display: grid;
      margin-right: 0;
      text-align: left;
    }

    div a {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 14px;
      padding: 12px 16px;
    }

    div.line-break {
      border-top: 1px solid $blue-gray-300;
      margin: 7px 17px;
    }
  }

  #user-dropdown div.highlight,
  #user-dropdown div:hover {
    background: $blue-gray-100;

    a {
      color: $blue-gray-800;
    }
  }

  nav {
    margin-bottom: 0;
    padding: 12px 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, 1fr));
    align-items: center;
    gap: 24px;
    justify-content: space-between;
  }

  #left-nav {
    display: flex;
  }

  #open-sidebar {
    margin-right: 16px;
  }

  .logo-container {
    flex: 0 0 auto;
    width: $logo-image-width;
    height: $logo-image-height;
  }

  #nav-search-form {
    position: relative;
    flex-basis: 440px;
    margin: 0;

    #search-submit {
      width: 28px;
      height: 28px;
      position: absolute;
      right: 16px;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      color: #b4bbd1;
      padding: 0;
      margin: 0;
      translate: 0 -50%;

      i {
        font-size: 20px;
        transition: color 200ms ease-out;
      }
    }

    input#nav-search-query {
      height: 40px;
      border-radius: 20px;
      border: 1px solid #868dab;
      margin: 0;
      padding: 0;
      padding-right: 40px;
      padding-left: 16px;
      color: $blue-gray-900;
    }

    input#nav-search-query:active,
    input#nav-search-query:focus {
      border: 1px solid $blue-dark;
    }

    input#nav-search-query:focus {
      outline: 3px auto -webkit-focus-ring-color;
      outline-offset: 3px;
    }

    input#nav-search-query::placeholder {
      color: #6d7594;
      opacity: 1;
    }
  }

  ul.nav-actions {
    justify-self: flex-end;
    display: flex;
    list-style: none;
    align-items: center;
    gap: 16px;
    margin: 0;
  }

  ul.nav-actions {
    li:last-child {
      position: relative;
    }

    .button {
      margin: 0;
    }

    .saved-link {
      font-weight: 600;
      line-height: 18px;

      a {
        display: flex;
        align-items: center;
      }

      i {
        margin-right: 3px;
        font-size: 18px;
      }
    }

    .signup-account-gating {
      white-space: nowrap;
    }
  }

  #search_weights_modal {
    position: absolute;
    right: auto;
    width: auto;
    margin: 0 auto;

    a.close {
      margin-right: 10px;
    }
  }

  .btn-rescore {
    height: 24px;
    position: absolute;
    right: 50px;
    top: 50%;
    border: none;
    font-size: 14px;
    height: 24px;
    padding: 0 10px;
    translate: 0 -50%;
  }
}

.user-avatar {
  border-radius: 50%;
  width: $avatar-size;
  height: $avatar-size;
}

.user-bubble {
  background-color: #6d7594;
  border-radius: 50%;
  width: $avatar-size;
  min-width: $avatar-size;
  height: $avatar-size;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: $avatar-size;
  font-weight: 600;
  user-select: none;
  text-transform: capitalize;
  transition: background-color 0.2s ease-in-out;
}

.user-bubble:hover {
  background-color: $blue-gray-600;
}

a#user-dropdown-trigger {
  display: block;
  border-radius: 50%;

  &:focus {
    outline-offset: 4px;
  }

  &:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 1024px) {
  header#main-nav nav {
    padding: 17.5px 0;
  }
}

@media screen and (max-width: 767px) {
  header#main-nav {
    ul.nav-actions.hidden-phone {
      display: none;
    }
    nav {
      grid-template-columns: min-content 1fr min-content;
    }
    #nav-search-form {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 330px) {
  header#main-nav {
    nav#top-navbar {
      flex-direction: column;

      #left-nav {
        align-self: flex-start;
      }

      #nav-search-form {
        flex-basis: auto;
        margin: 0;
        margin-top: 17.5px;
      }
    }
  }
}
