@import "vendor/normalize";
@import "app/assets/stylesheets/base/foundation-settings";

// Selectively include foundation components

@import "vendor/foundation/components/alert-boxes";
@import "vendor/foundation/components/block-grid";
@import "vendor/foundation/components/button-groups";
@import "vendor/foundation/components/buttons";
@import "vendor/foundation/components/dropdown";
@import "vendor/foundation/components/forms";
@import "vendor/foundation/components/grid";
@import "vendor/foundation/components/type";
@import "vendor/foundation/components/visibility";

// APP STYLES

@import "base/app-settings";
@import "partials/base";
@import "partials/header";
@import "partials/app-notification-banner";
@import "base/responsive";
@import "partials/osano";

html {
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  // For more details - https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;

  &.player-css-fullscreen {
    overflow-y: hidden;

    .new-recs-design.with-spillover-recs .content-page-centered {
      margin-bottom: 0;
    }
  }

  &.apply-scroll-lock {
    overflow-y: hidden;
  }

  &.sticky-ad {
    padding-top: 100px;
  }
}

#sticky-ad-scroll-detector {
  display: none;
}

.wrapper, #main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

header#main-nav nav#nav-sidebar {
  display: none;
}

.scribd-ad-exit-modal {
  &.hidden {
    display: none;
  }
}

.report-slideshow {
  &.hidden {
    display: none;
  }
}

.slideshow-likes-info,
.slideshow-views-info,
.subscription-success-learn-more,
.privacy-policy-explicit-opt-in,
.whitelist-slideshow-page,
.sign-up-popup-drawer-container {
  &.hidden {
    display: none;
  }
}

#fullscreen-css-backdrop {
  display: none;
  background-color: #000;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 100;
}

.tos-update-banner {
  background-color: #bddfdf;
  height: auto;
  width: auto;

  .tos-close {
    background: none;
    border: 0;
    color: #323b3a;
    float: right;
    font-size: 16px;
    padding: 3px 15px 0 0;
    text-decoration: none;
  }

  .text-div {
    .desktop-text {
      padding: 10px;
    }

    .mobile-text {
      line-height: 15px;
      margin-left: 8px;
      padding: 7px;

      a {
        color: #0077aa;
      }
    }

    a {
      font-weight: bold;
    }

    p {
      color: #323b3a;
      font-size: 15px;
      font-weight: bold;
      line-height: 18px;
      margin: 0;
      text-align: center;
    }
  }
}

// MAIN NAV

// fixes bugs introduced by the foundation 5.5.2 (see https://github.com/zurb/foundation/issues/6358)
.hide {
  display: none !important;
}

@media #{$screen} and (max-width: 45em) {
  #main-nav .sub-navbar .sub-nav-cats .sub-nav-cat {
    margin-right: 25px;
  }
}

// DOWNLOAD BANNER FOR SLIDEVIEW

.downloading-alert {
  display: none;
  text-align: center;
  margin: 0;
  z-index: 3;
  width: 100%;
  background-color: #337C41 !important;

  &.sticky-alert {
    position: sticky;
    top: 0;
  }
  &:not(.sticky-alert) {
    position: absolute;
  }

  .close {
    color: #FFF;
    opacity: 1;
  }
}

.copy-in-aria-label::before {
  content: attr(aria-label);
}

// Message bar
.global-message-bar-container {
  background: #2c404e;
  .message-bar {
    margin: 0 auto;
    font-weight: bold;
    padding: 10px 0;
    position: relative;
    max-width: 940px;
    width: auto;

    .clipping-message-bar {
      margin: 0;
      text-align: center;
    }

    .close {
      border-radius: 2px;
      border: 1px solid #516b7e;
      color: #516b7e;
      float: right;
      font-size: 12px;
      height: 15px;
      line-height: 15px;
      padding: 0px 4px 1px;
      position: absolute;
      right: 0;
      text-shadow: none;
      top: 10px;
      opacity: 0.8;
      margin-right: 16px;

      &:hover {
        border: 1px solid #6393a2;
        color: #6393a2;
        text-decoration: none;
      }
    }
  }
}

.inline_feedback {
  display: flex;
  gap: 5px;
  align-items: center;

  i {
    display: inline-block;
    vertical-align: top;
    flex-shrink: 0;
    color: #636C8B;

    &.notice {
      background-image: url("/images/notify_pebble_-_16_360.png");
    }

    &.warning {
      background-image: url("/images/notify_pebble_-_16_360.png");
      vertical-align: middle;
    }
  }

  .inline_feedback_text {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;

    &.notice {
      color: #636C8B;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .inline_feedback i {
    &.notice {
      background-image: url("/images/notify_pebble_-_16_2x_360.png");
    }
  }
}
