.app-notification-banner {
  background: #e7f5fc;
  color: #16171b;

  display: flex;
  flex-direction: row;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;

  padding: 18px 0;
  position: relative;
  width: 100%;

  .content-center {
    display: flex;
    align-items: center;

    .icon {
      display: flex;
      margin-right: 12px;
    }
  }

  &.hidden {
    display: none;
  }

  p {
    margin: 0;
  }

  a.notification-link {
    color: #16171b;
    text-decoration: underline;
    font-weight: 600;

    &.desktop {
      display: inline;
    }

    &.mobile {
      display: none;
    }
  }

  .notification-actions {
    position: absolute;
    right: 15px;
    top: 11px;
  }

  button.close-banner {
    background-color: transparent;
    border: none;
    color: #001a27;
    font-size: 26px;
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 928px) {
  /* Responsive */
  .app-notification-banner {
    padding: 24px 32px 24px 24px;
    width: auto;

    a.notification-link {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: inline;
      }
    }

    .notification-actions {
      top: 0;
      right: 10px;
    }
  }
}
